import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { formatUnits } from 'viem';

import type { GasPriceV2 } from 'types/api/stats';

const formatGwei = (val: string) => {
  return Number(formatUnits(BigInt(val || 0), 9)).toPrecision(2);
};

const GasInfoTooltipContent = ({ gasPrices }: { gasPrices: GasPriceV2 }) => {
  const nameStyleProps = {
    color: useColorModeValue('blue.100', 'blue.600'),
  };

  return (
    <Grid templateColumns="repeat(2, max-content)" rowGap={ 2 } columnGap={ 4 } padding={ 4 } fontSize="xs">
      <GridItem { ...nameStyleProps }>Slow</GridItem>
      <GridItem>{ gasPrices.slow !== null ? `${ formatGwei(gasPrices.slow) } Gwei` : 'N/A' }</GridItem>
      <GridItem { ...nameStyleProps }>Average</GridItem>
      <GridItem>{ gasPrices.average !== null ? `${ formatGwei(gasPrices.average) } Gwei` : 'N/A' }</GridItem>
      <GridItem { ...nameStyleProps }>Fast</GridItem>
      <GridItem>{ gasPrices.fast !== null ? `${ formatGwei(gasPrices.fast) } Gwei` : 'N/A' }</GridItem>
    </Grid>
  );
};

export default React.memo(GasInfoTooltipContent);
